/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
  // Flag to avoid bubbling on alt image click
  var altImageClickCheck = false;

  Drupal.behaviors.analyticsBehavior = {

    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [], i;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < productIds.length; i++) {
          positions.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var drupalSkuUpdate = Drupal.settings.analytics ? Drupal.settings.analytics.sku_product_update : false;
      var self = this;
      var eventObj = {};

      if (self.attached) {
        return;
      }

      // Event track common function call start
      function trackEvent(eName, eCategory, eAction, elabel) {
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
        eventObj = {};
      }
      // Event track common function call end

      // Track Brand Logo
      $('.js-site-header__utility-item__link-logo, .site-header__logo a', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

	  // Track Notify me button
      $('.js-add-to-waitlist').on('click', function() {
        var $targetElem = $(this).closest('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h1.product-full__name:first').text().trim();
        trackEvent('notify_me', 'ecommerce', 'notify me - request', prodName + ' - ' + prodId);
      });

      // Track Product Click in quickshop
      $(document).on('click', '.product__image, .js-quickshop-launch-inline, .js-spp-link .product-brief__name, .js-track-search-product .js-product-link', function () {
        var $currentElem = $(this);
        var prodElem = $currentElem.closest('.js-product-brief, .js-track-search-product');
        var prodId = prodElem.attr('data-product-id');
        var $searchProductsElem = $currentElem.closest('.esearch-product');
        var prodPosition = 0;
        var prodName = '';

        if ($searchProductsElem.length === 1) {
          prodPosition = Number($currentElem.closest('.js-product-grid-item, .js-track-search-product').index()) + 1;
          prodName = $searchProductsElem.find('.product-brief__description .product-brief__name span, .esearch-product__headline a').html().trim();
        }

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });
      // Track Quick Shop
      $(document).on('click', '.js-quickshop-launch-inline, .js-quickshop-launch-overlay', function () {
        var $targetElem = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElem.closest('.js-product-brief');
        var prodId = $targetElem.attr('data-product-id');
        var prodName = self.stripOutMarkup(prodElem.find('.product-brief__description').find('.product-brief__name span').html().trim());
       
        obj = {
          event_label: [prodId],
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.js-product-price').text().replace(/\s+/g, ' ').trim()]
        };
        var skuId = $targetElem.closest('.product-grid__item__content').find('.js-product-brief').data('sku-base-id') || $targetElem.closest('.esearch-product').find('.js-product-brief').data('sku-base-id');
        if (drupalSkuUpdate && skuId) {
          var skuData = prodcat.data.getSku(skuId);

          if (skuData.SHADENAME !== '' && skuData.SHADENAME !== null) {
            obj.product_shade = [skuData.SHADENAME];
          }
          if (skuData.PRODUCT_SIZE !== '' && skuData.PRODUCT_SIZE !== null) {
            obj.product_size = [skuData.PRODUCT_SIZE];
          }
          obj.product_price = [skuData.PRICE.toString()];
          obj.product_product_code = [skuData.PRODUCT_CODE];
          obj.product_sku = [skuId];
        }
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $(window).on('OMNISEARCH', function() {
        var prods, term;
        prods = $('.js-typeahead-wrapper a');
        term = $('.js-end-esearch-nav__form input#search').val();
        prods.on('click', function() {
          var obj;
          event.preventDefault();
          self.setLinkCapture($(this).attr('href'), '_self');
          obj = {
            event_label: term,
            search_keyword: term,
            product_sku: 'SKU' + $(this).closest('.product-brief').attr('data-sku-base-id'),
            product_id: $(this).closest('.js-product').attr('data-product-id'),
            product_name: self.stripOutMarkup($(this).closest('.js-product').find('h3 a').text())
          };
          site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
        });
      });

      // Track MPP Filters
      $(document).on('click', '.js-mpp-filter-set__button', function() {
        var obj, content_name;
        content_name = $(this).text().trim();
        var parentText = $(this).parent().siblings().text();
        obj = {
          event_name: 'filters_and_sort_selection',
          event_category: 'filter & sort selection',
          event_label: content_name,
          event_action: 'filter' + '-' + parentText
        };
        site.track.evtAction('filterProducts', obj);
      });

      // Track Sort By
      $(document).on('change', '.js-mpp-sort-menu', function() {
        var obj, content_name;
        content_name = $('option:selected', this).text();
        obj = {
          event_name: 'filters_and_sort_selection',
          event_category: 'filter & sort selection',
          event_action: 'sortby',
          event_label: content_name
        };
        site.track.evtLink(obj);
      });

      // CHECKOUT EVENTS
      // Track guest user checkout
      $(document).on('click', '#checkout_signin_guest_user-submit, #checkout_signin_new_user-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $(document).on('click', '#checkout_signin-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Track waitlist submit success
      $(document).on('notifySuccess', function(){
        var $targetElem = $('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h1.product-full__name').text().trim();

        trackEvent('notify_me', 'ecommerce', 'notify me - success', prodName + ' - ' + prodId);
      });

      // Track waitlist submit fail
      $(document).on('notifyFail', function(){
        var $targetElem = $('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h1.product-full__name').text().trim();

        trackEvent('notify_me', 'ecommerce', 'notify me - fail', prodName + ' - ' + prodId);
      });

      // Track Payment Method
      var paymentElements = [
        '#checkout_billing input.form-submit',
        '.js-chckt-button-containe',
        'input.js-paypal-continue-btn',
        '#checkout_review input.place-order',
        '#checkout_review input.continue-to-paypal'
      ];
      $(document).on('click', paymentElements.join(','), function() {
        var paymentLabel = '';
        var paymentTypePP = [
          'PP_PayPal',
          'PayPal'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'PP_Adyen'
        ];
        var paymentType = $('input[name=PAYMENT_TYPE]:checked').val() || $('input[name=PAYMENT_TYPE]').val() || $('input[name=PAYMENT_METHOD]:checked').val() || $('input[name=PAYMENT_METHOD]').val();
        if ($.inArray(paymentType, paymentTypePP) > -1) {
          paymentLabel = 'paypal';
        } else if ($.inArray(paymentType, paymentTypeCC) > -1) {
          paymentLabel = 'creditcard';
        }
        var obj = {
          event_label: paymentLabel
        };
        if (paymentLabel.length !== 0) {
          site.track.evtAction('checkoutPaymentSelected', obj);
        }
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // END CHECKOUT EVENTS

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Trigger Alt image event only when alternate images are available
        if ($('body').hasClass('device-pc') && $('.js-spp-carousel__thumbnail').length > 1) {
          trackAltImageTrackingClickPC();
          trackAltImageTrackingDragPC();
        } else if ($('body').hasClass('device-mobile')) {
          trackAltImageTrackingMobile();
        }
      }

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        $(document).on('click', '.js-spp-carousel__thumbnail, .slick-arrow', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails;
          var bgImageUrl;
          var $activeSlideImageElem = '';

          altImageClickCheck = true;
          if ($targetElem.hasClass('js-spp-carousel__thumbnail')) {
            prodDetails = buildAltImageProductName($targetElem);
            bgImageUrl = $targetElem.find('.js-product-image').attr('src');
          } else if ($targetElem.hasClass('slick-arrow')) {
            prodDetails = buildAltImageProductName($targetElem);
            $activeSlideImageElem = $('.active-main.slick-active > img.js-product-image').first();
            bgImageUrl = $activeSlideImageElem.length === 1 ? ($activeSlideImageElem.attr('src') || $activeSlideImageElem.attr('data-src')) : '';
          }
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
        });
      }

      // Track Alt image drag - desktop
      function trackAltImageTrackingDragPC() {
        $(document).on('afterChange', '.js-spp-carousel', function() {
          if (!altImageClickCheck) {
            var $targetElem = $(this);
            var altImageName = '';
            var prodDetails = buildAltImageProductName($targetElem);
            var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
            if (bgImageUrl) {
              altImageName = buildAltImageFileName(bgImageUrl);
            }
            trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'drag');
          }
          altImageClickCheck = false;
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(document).on('afterChange', '.js-spp-carousel', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__content .product-full__name').text();
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Foundation finder tagging start.
      if ($('body').hasClass('section-shade-finder')) {
        trackFoundationTagging();
      }
      function trackFoundationTagging() {
        initFoundationTagging();

        function trackFinderfilter() {
          $(document).on('click', '.js-form-formatter__item .js-mantle-form-options-field__items .mantle-form-options-field__item .js-mantle-form__checkbox', function() {
            var quizLabel = $(this).closest('.js-form-formatter__item').data('field-name');
            var quizAnswer = $(this).find('.label-content').text().trim();
            var stepCount = $(this).closest('.js-form-formatter__item').data('field-order') + 2;
            var eventLabel = quizLabel + ' - ' + quizAnswer;
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'questions', eventLabel);
            trackNextPage('/foundation-finder/step' + stepCount, '/step_' + stepCount, 'foundation-finder - Step ' + stepCount);
          });
        }

        function trackFinderNavLabel() {
          $(document).on('click', '.js-form-indicator-menu .js-form-indicator.active', function() {
            var finderNavPosition = $(this).data('index') + 1;
            trackNextPage('/foundation-finder/step' + finderNavPosition, '/step_' + finderNavPosition, 'foundation-finder - Step ' + finderNavPosition);
          });
        }

        function trackCarousel() {
          $(document).on('click', '.js-basic-carousel__slide', function() {
            var skintone = $(this).hasClass('slide-selected-finished');
            if (!skintone) {
              trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'carousel click', 'click');
            }
          });

          $(document).on('swipe', '.js-range-slider-carousel__carousel', function() {
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'carousel click', 'click');
          });
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + location.pathname + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function trackResultPage() {
          $(document).on('filterResult', function(event, formAnswer) {
            var quizSkinLabel = $('.mantle-form-options-field__footer .js-mantle-cta-button').closest('.js-form-formatter__item').data('field-name');
            var quizSkinAnswer = formAnswer.skin_tone;
            var eventLabel = quizSkinLabel + ' - ' + quizSkinAnswer;
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'questions', eventLabel);
            var $menuItems = $('.js-quiz-landing-page-formatter__results');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.foundation-finder-result__main-info').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.find('.foundation-finder-result__main-info__product-price .js-sku-price').attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/results');
            });

            $menuItems.find('.js-quiz-recommendation').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-product-id'));
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/also_recommended');
            });
            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results',
              page_url: '/foundation-finder/result',
              page_name: 'foundation-finder - result'
            };
            site.track.evtView(objView);
          });
        }

        function trackStartOver() {
          $(document).on('click', '.start-over-link', function() {
            var eventLabel = $(this).text().trim();
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'click', eventLabel);
            trackNextPage('/foundation-finder/step1', '/step_1', 'foundation-finder - Step 1');
          });
        }

        function initFoundationTagging() {
          trackFinderfilter();
          trackFinderNavLabel();
          trackCarousel();
          trackResultPage();
          trackStartOver();
        }
      }
      // Foundation finder tagging end.

      /* MTA-4222 update datalayer with sku details on SPP page load
      since the product files are loaded prior to analytics generic files */
      $(document).on('productSkuSelectOnLoad', function (event, skuBaseId) {
        if ($('body').hasClass('section-product')) {
          if (prodcat && prodcat.data) {
            var skuData = prodcat.data.getSku(skuBaseId);

            if (!skuData) {
              return;
            }
            var productId = skuData.PRODUCT_ID.toString();
            var skuObj;

            skuObj = {
              product_sku: [skuData.SKU_ID],
              product_id: [skuData.PRODUCT_ID],
              product_product_code: [skuData.PRODUCT_CODE],
              product_price: [skuData.PRICE.toString()]
            };

            if (skuData.SHADENAME !== '' && skuData.SHADENAME !== null) {
              skuObj.product_shade = [skuData.SHADENAME];
            }
            if (skuData.PRODUCT_SIZE !== '' && skuData.PRODUCT_SIZE !== null) {
              skuObj.product_size = [skuData.PRODUCT_SIZE];
            }

            if (Drupal.behaviors.analyticsSkuChange) {
              Drupal.behaviors.analyticsSkuChange.sppSelectedSkuBaseId = skuBaseId;
              Drupal.behaviors.analyticsSkuChange.sppProductId = productId;

              site.trackingDataLayer.update(skuObj);
            }
          }
        }
      });

      // Sort & Filter tagging start.
      if ($('div').hasClass('js-filter-sort-toggle-bar')) {
        $(document).on('mpp-sort', function (event, data) {
          var eventLabel = data;

          trackEvent('filters_and_sort_selection', 'filter & sort selection', 'sort - MPP', eventLabel);
        });

        $(document).on('mpp-filter', function () {
          var filterArray = [];
          $('.js-mpp-filter-set__link.active').each(function () {
            filterArray.push($(this).data('filter-key'));
          });
          var eventLabel = filterArray.join("|");

          trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - MPP', eventLabel);
        });

        $(document).on('mpp-filter-reset', function () {
          trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - MPP', 'Clear All');
        });
      }
      // Sort & Filter tagging end.

      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-samples-panel-toggle', function () {
        var $targetElem = $(this).closest('#viewcart-samples-panel');
        var clickSense = $targetElem.find('.js-samples-content').is(':visible');
        var evtLabel = $targetElem.find('.checkout-panel__heading').text().trim();

        if (clickSense) {
          trackEvent('sample_module_close', 'samples', 'module_close', evtLabel);
        } else {
          trackEvent('sample_module_open', 'samples', 'module_open', evtLabel);
        }
      });

      // Tracking arrow clicks
      $(document).on('mousedown touchdown', '.js-samples-panel-prev, .js-samples-panel-next', function () {
        var $currentElem = $(this);
        var carouselName = $currentElem.closest('#viewcart-samples-panel').find('.checkout-panel__heading').text().trim();

        if (!($currentElem.hasClass('disabled') || $currentElem.hasClass('slick-disabled')) && carouselName) {
          trackEvent('sample_arrow_click', 'samples', 'arrow_click', carouselName);
        }
      });

      // Sample Remove tracking
      $(document).on('click', 'a.js-sample-select-button', function () {
        var $currentElem = $(this);
        var $targetElem = $currentElem.closest('div.details');
        var $skuSelected = $targetElem.find('.sample-select');
        var skuId = $skuSelected.val();
        var skuName = $targetElem.find('.product-brief__name').text().trim();

        skuId = (skuId && skuId.toUpperCase().includes('SKU')) ? skuId : 'SKU' + skuId;
        if (!$currentElem.hasClass('selected')) {
          trackEvent('samples', 'samples', 'samples_remove', skuName + ' - ' + skuId);
        }
      });

      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
